import React from "react";
import { useTranslation } from "react-i18next";

import {
	showThemeDetailsPage,
	showThemesPage
} from "../../../../services/navigation/navigation.service";
import { DashboardController } from "../../../../controllers/dashboard-controller/DashboardController";

import SlickSlider from "../../../../components/SlickSlider/SlickSlider";
import useBanners from "../../../../hook/useBanners";

import "./JustAddedComponent.scss";

const JustAddedComponent = (props) => {
	const { showFilters } = props;

	const main = useBanners('main', 1);
	const promo = useBanners('promo', 1);

	const { t } = useTranslation();
	const filters = DashboardController.getDefaultThemes() || [];

	const renderFilters = () => {
		if (filters.length === 0 || !showFilters) return null;

		return (
			<div className="Filters">
				<div className="Label">{t('JustAddedComponent.showMe')}</div>

				<div className="Filters-inner">
					{filters.map(item => {
						const { id, name } = item;
						return (
							<span
								className='FilterItem'
								onClick={() => showThemeDetailsPage(id, name)}
								key={'theme-' + id}
							>
								{name}
							</span>
						)
					})}
				</div>

				<div className="SeeMore" onClick={showThemesPage}>{t("JustAddedComponent.seeMore")}</div>
			</div>
		);
	};

	return (
		<>
			<div className='JustAddedComponent'>
				<SlickSlider renderPreview slides={main} />
				{renderFilters()}
				<SlickSlider renderPreview slides={promo} />
			</div>
		</>
	);
}

JustAddedComponent.displayName = 'JustAddedComponent';

export default JustAddedComponent;