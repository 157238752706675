import axios from "axios";
import { isUserLoggedIn } from "../token-service/token.service";
import { getUserNATSCode } from "../nats-service/nats.service";

const urls = {
	bannersSet: "/frontend/banners_set",
	bannerClick: "/frontend/banners/click",
};

export const bannerSets = {
	benefits: {
		affiliate: 73,
		defaultSet: 71,
		member: 72
	},
	defaultOriginals: {
		affiliate: 120,
		defaultSet: 120,
		member: 120
	},
	featuredReleases: {
		affiliate: 78,
		defaultSet: 64,
		member: 65
	},
	featuredReleasesLeft: {
		affiliate: 118,
		defaultSet: 118,
		member: 118
	},
	featuredReleasesRight: {
		affiliate: 119,
		defaultSet: 119,
		member: 119
	},
	main: {
		affiliate: 77,
		defaultSet: 12,
		member: 13,
	},
	// originalsHeading: {
	// 	affiliate: "",
	// 	defaultSet: 67,
	// 	member: ""
	// },
	originalsMiddle: {
		affiliate: "",
		defaultSet: 68,
		member: "",
	},
	playlists: {
		affiliate: "",
		defaultSet: 60,
		member: ""
	},
	playlistsHeader: {
		affiliate: "",
		defaultSet: 66,
		member: ""
	},
	stars: {
		affiliate: "",
		defaultSet: 61,
		member: ""
	},
	studios: {
		affiliate: "",
		defaultSet: 63,
		member: ""
	},
	themes: {
		affiliate: "",
		defaultSet: 62,
		member: ""
	},
	tour: {
		affiliate: 79,
		defaultSet: 69,
		member: 70
	},
	promo: {
		affiliate: 77,
		defaultSet: 67,
		member: 59
	},
	memberUpsellBottom: {
		affiliate: "",
		defaultSet: 80,
		member: ""
	},
	promoNonMember: {
		affiliate: "",
		defaultSet: 67,
		member: ""
	},
	nonMemberPromoAffiliate: {
		affiliate: "",
		defaultSet: 77,
		member: ""
	},
	exit: {
		affiliate: "",
		defaultSet: 81,
		member: ""
	},
	weekends: {
		affiliate: '',
		defaultSet: 122,
		member: 123,
		typein: ''
	},
	whatMembershipBringsYou: {
		affiliate: "",
		defaultSet: 125,
		member: ""
	}
};

export const bannerRankings = [{
	key: "member",
	rankerFn: isUserLoggedIn
}];

export const getBannerSet = (bannerSetId, page) => {
	const userNATSCode = getUserNATSCode();

	return axios.get(`${urls.bannersSet}/feed`, {
		params: {
			id: bannerSetId,
			page,
			nats_code: userNATSCode
		},
		withCredentials: true
	});
};

export const getBenefitsBannerSet = (page) => getUserBannerSet("benefits", page);

export const getPlaylistsBannerSet = (page) => {
	return getUserBannerSet("playlists", page);
};

export const getUserStateBannerSetId = (setName) => {
	const keys = bannerSets[setName];
	let setId;
	if (Object.keys(keys).length > 1) {
		// More banner sets exist
		let item = 0;
		const itemsCount = bannerRankings.length;
		let currentRank;
		let isMatch;
		while (!setId && item < itemsCount) {
			currentRank = bannerRankings[item];
			isMatch = currentRank.rankerFn();
			if (keys[currentRank.key] && isMatch) {
				setId = keys[currentRank.key];
			}
			item++;
		}
		if (!setId) {
			setId = keys.defaultSet;
		}
	} else {
		setId = keys.defaultSet;
	}

	return setId;
};

export const getUserBannerSet = (setName, page) => {
	return getBannerSet(getUserStateBannerSetId(setName), page);
};

export const registerBannerClick = (bannerId, bannerSetId) => {
	return axios.post(`${urls.bannerClick}?banners_id=${bannerId}&banners_set_id=${bannerSetId}`, {
		data: {
			banners_id: bannerId,
			banners_set_id: bannerSetId,
		},
	});
};

export const parseBannerUrl = (url) => {
	if (!url) {
		return {};
	}
	if (url.startsWith("/movies")) {
		return parseBannerUrlForMovieData(url);
	}
	if (url.startsWith("/studios")) {
		return parseBannerUrlForStudioData(url);
	}
	if (url.startsWith("/stars")) {
		return parseBannerUrlForStarData(url);
	}
	if (url.startsWith("/theme")) {
		return parseBannerUrlForThemeData(url);
	}
	if (url.startsWith("/playlists")) {
		return parseBannerUrlForPlaylistData(url);
	}
	return {};
};

export const parseBannerUrlForMovieData = (url) => {
	url = url.replace("/movies/", "");
	let backslashIndex = url.indexOf("/");
	const movieId = url.slice(0, backslashIndex);
	url = url.replace(`${movieId}/`, "");
	backslashIndex = url.indexOf("/");
	let movieName = url;
	if (backslashIndex !== -1) {
		movieName = url.slice(0, backslashIndex);
	}
	const sceneIndex = url.indexOf("/scene");
	let sceneId;
	if (sceneIndex !== -1) {
		sceneId = url.slice(sceneIndex);
		sceneId = sceneId.replace("/scene/", "");
	}

	return { movieId, movieName, sceneIndex: sceneId };
};

export const parseBannerUrlForPlaylistData = (url) => {
	url = url.replace("/playlists/", "");
	let backslashIndex = url.indexOf("/");
	const playlistId = url.slice(0, backslashIndex);
	const remainder = url.slice(backslashIndex + 1);
	const data = {};
	if (remainder.startsWith("movies")) {
		data.moviePlaylistId = playlistId;
		data.playlistName = remainder.replace("movies/", "");
	} else {
		data.scenePlaylistId = playlistId;
		data.playlistName = remainder.replace("scenes/", "");
	}
	return data;
};

export const parseBannerUrlForStudioData = (url) => {
	url = url.replace("/studios/", "");
	let backslashIndex = url.indexOf("/");
	const studioId = url.slice(0, backslashIndex);
	const studioName = url.slice(backslashIndex + 1);
	return { studioId, studioName };
};

export const parseBannerUrlForStarData = (url) => {
	url = url.replace("/stars/", "");
	let backslashIndex = url.indexOf("/");
	const starId = url.slice(0, backslashIndex);
	const starName = url.slice(backslashIndex + 1);
	return { starId, starName };
};

export const parseBannerUrlForThemeData = (url) => {
	url = url.replace("/theme/", "");
	let backslashIndex = url.indexOf("/");
	let themeName = url.slice(0, backslashIndex);
	const themeId = url.slice(backslashIndex + 1);
	if (backslashIndex === -1) {
		themeName = themeId;
	}
	return { themeId, themeName };
};
