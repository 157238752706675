



import React from 'react'
import { Link } from '@reach/router';

import { ROUTES } from '../../services/navigation/navigation.routes';

// import { links } from '../../links';
import { env } from '../../env';

import './Footer.scss';

function Footer() {


  return (
      <div className='Footer'>
        <div className='FooterLinks'>
          {/* <a target='_blank' rel='noreferrer' href={links.twitter}>
          Follow us on instagram
        </a>
        <a target='_blank' rel='noreferrer' href={links.instagram}>
          Follow us on twitter
        </a> */}

          {/* <Link to={ROUTES.help}>FAQ &amp; Support</Link> */}
          <Link to={ROUTES.termsOfUse}>Terms of use</Link>
          <Link to={ROUTES.privacyPolicy}>Privacy policy</Link>
        </div>


        <div className='FooterText'>
          <div className='FT-Inner'>
            <p>All performers are at least 18 years of age.</p>
            <p>
              <Link to={ROUTES.compliance}>"18 U.S.C 2257 Record Keeping Requirements Compliance Statement"</Link>
            </p>

            <div className='ContentRemovalContainer'>
              <a href='https://www.aebn.com/contentremoval' target='_blank' rel='noreferrer'>Content removal</a>
            </div>

            <div className='mb-1em'>Copyright © {env.REACT_APP_SITE_NAME} All Rights Reserved.</div>

            <div>
              <a href='https://www.rtalabel.org' target='_blank' rel='noreferrer' className='FT-icon' style={{ backgroundImage: 'url(/img/rta-logo.svg)' }}>rta logo</a>
              <a href='https://www.freespeechcoalition.com/' target='_blank' rel='noreferrer' className='FT-icon' style={{ backgroundImage: 'url(/img/fsc-logo.svg)' }}>fsc logo</a>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Footer;